
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import AddUserModal from "@/views/studio-lab/components/modals/AddUserModal.vue";
import RoomService from "@/core/services/RoomService";
import { IRoom } from "@/core/data/room";
import { IRoomGroup } from "@/core/data/roomGroups";
import { IRoomGroupMember } from "@/core/data/roomGroupMember";
import RoomGroupService from "@/core/services/RoomGroupService";
import Swal from "sweetalert2";
export default defineComponent({
  components: { AddUserModal },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const addRoomGroupMemberModal = ref();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const roomGroupData = ref<IRoomGroup>({
      id: null,
      room: null,
      name: "",
      members: [],
    });
    const excludedUserIds = ref<Array<number>>([]);
    const roomGroupMembers = ref<Array<IRoomGroupMember>>([]);
    const currentRoom = ref<IRoom>();
    const deleteMemberIdList = ref<any[]>([]);
    const rules = computed(() => {
      return {
        name: [
          {
            required: true,
            message: "Please input group name.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Group name must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
      };
    });

    const initRoom = () => {
      RoomService.getRoomById(route.params.roomId).then((response) => {
        currentRoom.value = response.data;
      });
    };

    const initRoomGroup = () => {
      RoomGroupService.getRoomGroupById(route.params.groupId).then(
        (response) => {
          roomGroupData.value = response.data;
          currentRoom.value = roomGroupData.value.room!;
          initRoomGroupMember();
        }
      );
    };

    const initRoomGroupMember = () => {
      roomGroupMembers.value = [];
      roomGroupData.value.members?.forEach((member) => {
        roomGroupMembers.value.push(member);
        excludedUserIds.value.push(member!.user!.id!);
      });
    };

    const goBack = () => {
      router.back();
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          var formData = new FormData();

          roomGroupMembers.value.forEach((groupUser) => {
            formData.append("roomGroupMembers[]", JSON.stringify(groupUser));
          });

          if (roomGroupData.value.id == null) {
            roomGroupData.value.room = currentRoom!.value!;
            formData.append(
              "roomGroupData",
              JSON.stringify(roomGroupData.value)
            );
            RoomGroupService.createRoomGroup(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                goBack();
              })
              .catch(({ response }) => {
                loading.value = false;
                goBack();
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          } else {
            formData.append(
              "roomGroupData",
              JSON.stringify(roomGroupData.value)
            );
            deleteMemberIdList.value.forEach((roomGroupMemberId) => {
              formData.append(
                "delete_room_group_member_id_list[]",
                roomGroupMemberId
              );
            });
            RoomGroupService.updateRoomGroup(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                goBack();
              })
              .catch(({ response }) => {
                loading.value = false;
                goBack();
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }
        } else {
          return false;
        }
      });
    };

    const openUserDialog = () => {
      addRoomGroupMemberModal.value.showModal();
    };

    const addGroupMember = (user) => {
      roomGroupMembers.value.push({
        id: null,
        user: user,
        roomGroup: null,
      });
      excludedUserIds.value.push(user.id);
    };

    const deleteMember = (groupUser) => {
      let deleteMemberIndex = roomGroupMembers.value.findIndex(
        (obj) => obj.user!.id === groupUser.user.id
      );

      if (roomGroupMembers.value[deleteMemberIndex].id != null) {
        deleteMemberIdList.value.push(
          roomGroupMembers.value[deleteMemberIndex].id
        );
      }

      roomGroupMembers.value.splice(
        roomGroupMembers.value.indexOf(groupUser),
        1
      );
      excludedUserIds.value.splice(
        excludedUserIds.value.indexOf(groupUser.user.id),
        1
      );
    };

    onMounted(() => {
      if (route.params.roomId != undefined) {
        initRoom();
      } else if (route.params.groupId != undefined) {
        initRoomGroup();
      }
    });

    return {
      formRef,
      loading,
      roomGroupData,
      rules,
      onSubmit,
      openUserDialog,
      addGroupMember,
      addRoomGroupMemberModal,
      deleteMember,
      excludedUserIds,
      roomGroupMembers,
      goBack,
      currentRoom,
    };
  },
});
